import * as React from "react";
import "./post.scss";
import moment from "moment";
import { graphql } from "gatsby";
import { WordpressPostQuery } from "graphql-types";
import Hero, { headerStyle } from "src/components/Organisms/Hero/hero";
import {
    EmailShareButton,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    PinterestShareButton,
    RedditShareButton,
    TwitterShareButton,
    TwitterIcon,
    LinkedinIcon,
    PinterestIcon,
    RedditIcon,
    EmailIcon,
} from "react-share";

export default class Post extends React.PureComponent<{
    data: WordpressPostQuery;
    location: string;
}> {
    public render() {
        return (
            <>
                <Hero isBlog
                {... {
                    style: headerStyle(require("src/components/Organisms/images/banner/homepage.jpg")),
                    header: <b dangerouslySetInnerHTML={{__html: this.props.data.wordpressPost?.title || ''}} />,
                    subLine: this.props.data.wordpressPost?.categories?.[0]?.name?.replace('&amp;', '&') || '',
                    buttonLink: ""
                }} />
                <div className="container-fluid section-padding-tb96 blog-post">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-lg-12 ">
                                <div className="sub-content">
                                    <div className="sub-cont__contet-cont__meta">
                                        <div>
                                            <i className="far fa-calendar-alt"></i>
                                            {moment(this.props.data.wordpressPost?.date).format('YYYY-MM-DD')}
                                        </div>
                                        <div>
                                            <i className="far fa-calendar-alt"></i>
                                            <a href={this.props.data.wordpressPost?.categories?.[0]?.link || '/'}>{this.props.data.wordpressPost?.categories?.[0]?.name?.replace('&amp;', '&')}</a>
                                        </div>
                                    </div>
                                    <div className="sub-cont__contet-cont__bodytxt" dangerouslySetInnerHTML={{__html: this.props.data.wordpressPost?.content || ''}}>
                                    </div>
                                    <div className="sub-cont__contet-cont__bodytxt">
                                        <FacebookShareButton
                                            url={this.props.data.wordpressPost?.link || ''}
                                            quote={this.props.data.wordpressPost?.title || ''}
                                            className="Demo__some-network__share-button"
                                        >
                                            <FacebookIcon size={32} round />
                                        </FacebookShareButton>
                                        <TwitterShareButton
                                            url={this.props.data.wordpressPost?.link || ''}
                                            title={this.props.data.wordpressPost?.title || ''}
                                            className="Demo__some-network__share-button"
                                        >
                                            <TwitterIcon size={32} round />
                                        </TwitterShareButton>
                                        <LinkedinShareButton
                                            url={this.props.data.wordpressPost?.link || ''}
                                            title={this.props.data.wordpressPost?.title || ''}
                                            className="Demo__some-network__share-button"
                                        >
                                            <LinkedinIcon size={32} round />
                                        </LinkedinShareButton>
                                        <PinterestShareButton
                                            url={this.props.data.wordpressPost?.link || ''}
                                            media={this.props.data.wordpressPost?.title || ''}
                                            className="Demo__some-network__share-button"
                                        >
                                            <PinterestIcon size={32} round />
                                        </PinterestShareButton>
                                        <RedditShareButton
                                            url={this.props.data.wordpressPost?.link || ''}
                                            title={this.props.data.wordpressPost?.title || ''}
                                            className="Demo__some-network__share-button"
                                        >
                                            <RedditIcon size={32} round />
                                        </RedditShareButton>
                                        <EmailShareButton
                                            url={this.props.data.wordpressPost?.link || ''}
                                            title={this.props.data.wordpressPost?.title || ''}
                                            className="Demo__some-network__share-button"
                                        >
                                            <EmailIcon size={32} round />
                                        </EmailShareButton>
                                    </div>
                                    <div className="sub-cont__content-cont__subbox">
                                        <div id="mc_embed_signup">
                                            <form
                                                action="https://wixsite.us9.list-manage.com/subscribe/post?u=4b0c169307b43f4b0b01e503c&amp;id=c4c17ddb0d"
                                                method="post"
                                                id="mc-embedded-subscribe-form"
                                                name="mc-embedded-subscribe-form"
                                                className="validate"
                                                target="_blank"
                                            >
                                                <div id="mc_embed_signup_scroll">
                                                    <h2>Subscribe</h2>
                                                    <div className="mc-field-group">
                                                        <label>
                                                            Email Address{" "}
                                                        </label>
                                                        <input
                                                            type="email"
                                                            value=""
                                                            name="EMAIL"
                                                            className="required email"
                                                            id="mce-EMAIL"
                                                        />
                                                    </div>
                                                    <div
                                                        id="mce-responses"
                                                        className="clear "
                                                    >
                                                        <div
                                                            className="response"
                                                            id="mce-error-response"
                                                        />
                                                        <div
                                                            className="response"
                                                            id="mce-success-response"
                                                        />
                                                    </div>
                                                    {/* Prevent bot start */}
                                                    <div
                                                        hidden={true}
                                                        aria-hidden="true"
                                                    >
                                                        <input
                                                            type="text"
                                                            name="b_4b0c169307b43f4b0b01e503c_c4c17ddb0d"
                                                            value=""
                                                        />
                                                    </div>
                                                    {/* Prevent bot end */}
                                                    <div className="clear">
                                                        <input
                                                            type="submit"
                                                            value="Subscribe"
                                                            name="subscribe"
                                                            id="mc-embedded-subscribe"
                                                            className="button"
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export const pageQuery = graphql`
query WordpressPost ($id: String!) {
    wordpressPost(id: {eq: $id}) {
    title
    content
    date
    link
    categories {
        name
        link
    }
    }
}
`;